import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const RetrievalFormStyle = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  &.error {
    min-height: 690px !important;
  }
  .button {
    padding: 16px 28px;
  }
  .form-element {
    max-height: 60px !important;
    margin: 0 !important;
    label {
      color: #48e1bb;
    }
    input {
      height: 60px !important;
    }
    .submitted {
      input {
        height: 30px !important;
      }
    }
  }

  @media (min-width: 769px) {
    padding-top: 34px;
    padding-left: 45px;
    padding-right: 47.5px;
    border-radius: 4px;
  }
  .title {
    margin-bottom: 15px;
  }
  .sub-title {
    margin-bottom: 30px;
  }
  .input-wrapper {
    margin-bottom: ${theme.grid.spacing.s}px;
  }
  .form-label {
    color: ${theme.primaryColours.slateGray.main};
    margin-bottom: ${theme.grid.spacing.s}px;
  }
  .split-date-error {
    margin-bottom: ${theme.grid.spacing.s}px;
  }
  button {
    width: 100% !important;
    @media (min-width: 768px) {
      width: 179px !important;
    }
  }
  .form-divider {
    margin: 25px 0;
    border: 1px solid ${theme.primaryColours.paleGray.main};
    @media (min-width: 1170px) {
      margin: 35px 0;
    }
    &.error {
      margin-bottom: 16px;
    }
  }
  .errors {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    background-color: floralwhite;
    color: #fcb608;
    border-top: 1px solid #fcb608;
    border-bottom: 1px solid #fcb608;
    padding: 10px 0 10px;
    margin-top: 30px;
  }
  .button-wrapper {
    text-align: right;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 1170px) {
      top: -12px;
    }

    button {
      margin-bottom: 24px;
      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
  .auto-month {
    position: relative;
    input {
      position: relative;
      top: -12px;
    }
    .submitted {
      input {
        position: relative;
        top: 0;
      }
      div {
        left: 0 !important;
      }
    }
  }
`
